export default {
  lienertApplicantDetails: {
    actions: {
      backButton: 'Zurück',
      toCandidateButton: 'Zum Kandidaten'
    },
    error: {
      loadingError: 'Fehler beim Laden des Kandidatenprofils',
      noData: 'Keine Daten gefunden'
    },
    title: {
      headerData: 'Persönliche Informationen',
      headerMatchingProfile: 'Matching Profil',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'E-Mail',
      phoneNumber: 'Telefonnummer',
      street: 'Straße',
      birthDate: 'Geburtsdatum',
      postalCode: 'Postleitzahl',
      city: 'Stadt'
    }
  },
  searchField: {
    placeHolder: 'Kandidaten Bewerberportal suche'
  },
  tableTitle: 'Kandidatenprofile',
  tableCellHeader: {
    title: 'Anrede',
    firstName: 'Vorname',
    lastName: 'Nachname',
    mail: 'E-Mail',
    phoneNumber: 'Telefonnummer',
    birthDate: 'Geburtsdatum',
    street: 'Straße',
    postalCode: 'Postleitzahl',
    city: 'Stadt',
    createdAt: 'Registriert am'
  }
};
