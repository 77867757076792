import moment from 'moment';
import TEXT from './text';

const Title = {
  MR: 'Herr',
  MS: 'Frau'
};

const sortDateCompare = order => (a, b) =>
  moment(a)
    .subtract(b)
    .unix() * (order === 'asc' ? 1 : -1);
/**
 * @type {import('mui-datatables').MUIDataTableColumnDef[]}
 */
const DEFAULT_COLUMNS = [
  {
    name: '_id',
    options: {
      display: 'excluded',
      sort: false,
      filter: false
    }
  },
  {
    name: 'title',
    label: TEXT.tableCellHeader.title,
    options: {
      customBodyRender: value => Title[value] || ''
    }
  },
  {
    name: 'firstName',
    label: TEXT.tableCellHeader.firstName
  },
  {
    name: 'lastName',
    label: TEXT.tableCellHeader.lastName
  },
  {
    name: 'mail',
    label: TEXT.tableCellHeader.mail
  },
  {
    name: 'phoneNumber',
    label: TEXT.tableCellHeader.phoneNumber
  },
  {
    name: 'birthDate',
    label: TEXT.tableCellHeader.birthDate,
    options: {
      customBodyRender: value => moment(value).format('DD.MM.YYYY'),
      sortCompare: sortDateCompare
    }
  },
  {
    name: 'street',
    label: TEXT.tableCellHeader.street
  },
  {
    name: 'postalCode',
    label: TEXT.tableCellHeader.postalCode
  },
  {
    name: 'city',
    label: TEXT.tableCellHeader.city
  }
];

const applicantColumns = {
  DEFAULT_COLUMNS
};

export default applicantColumns;
