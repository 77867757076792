import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withUser from '../utils/withUser';
import * as CONSTANTS from '../constants';
import isPermissionSet from '../utils/isPermissionSet';

const linksMainMenu = [
  {
    to: '/companies',
    text: 'Unternehmen',
    materialIcon: 'domain'
  },
  {
    to: '/projects',
    text: 'Aufträge',
    materialIcon: 'question_answer'
  },
  { to: '/candidates', text: 'Kandidaten', materialIcon: 'group' },
  {
    to: '/intranet',
    text: 'Intranet',
    materialIcon: 'description',
    hide: !CONSTANTS.isPersonalTotal && !CONSTANTS.isSenator
  }
];
const linksGeneralMenu = [
  {
    to: '/reminders',
    text: 'Wiedervorlagen',
    materialIcon: 'alarm'
  },
  {
    to: '/notifications',
    text: 'Benachrichtigungen',
    materialIcon: 'notifications'
  },
  {
    to: '/markLists',
    text: 'Merklisten',
    materialIcon: 'list'
  },
  {
    to: '/offers',
    text: 'Offerten',
    materialIcon: 'work_outline'
  },
  {
    to: '/search',
    text: 'Semantische Suche',
    materialIcon: 'search'
  }
];
const linksAdminMenu = [
  {
    to: '/incomingCandidates',
    text: 'Eingehende Kandidaten',
    materialIcon: 'group_add',
    isAdminLink: true,
    additionalPermission: 'incomingCandidate'
  },
  {
    to: '/applicantUser',
    text: 'Kandidatenprofile',
    materialIcon: 'group_add',
    isAdminLink: true,
    hide: !CONSTANTS.isLienert
  },
  {
    to: '/incomingMatchingProfiles',
    text: 'Matching Eingang',
    materialIcon: 'people',
    isAdminLink: true,
    hide: !CONSTANTS.isLienert
  },
  {
    to: '/settings',
    text: 'Einstellungen',
    materialIcon: 'supervisor_account',
    isAdminLink: false
  }
];
const linksBottomMenu = [
  {
    to: '/imprint',
    text: 'Impressum',
    materialIcon: 'info'
  },
  {
    to: '/privacy',
    text: 'Datenschutz',
    materialIcon: 'info'
  }
];
class MainMenuDrawer extends React.Component {
  renderMainMenuLinks(menuArray) {
    const array = [];
    const excludeFromMenu = typeof CONSTANTS.excludeMenu[CONSTANTS.partner] !== 'undefined' ? CONSTANTS.excludeMenu[CONSTANTS.partner] : [];

    menuArray.forEach(link => {
      if (
        (!link.showFor || link.showFor.includes(this.props.user.id)) &&
        !link.hide &&
        (!link.isAdminLink || isPermissionSet(this.props.user, link.additionalPermission)) &&
        !excludeFromMenu.includes(link.to)
      )
        array.push(
          <ListItem
            key={link.text}
            button
            component={Link}
            to={link.to}
            color="inherit"
            onClick={() =>
              this.props.liftUpStateToApp({
                mainMenuOpen: false
              })
            }
          >
            <ListItemIcon>
              <i className="material-icons">{link.materialIcon}</i>
            </ListItemIcon>
            <ListItemText primary={link.text} />
          </ListItem>
        );
    });
    return array;
  }

  renderPageMenuLinks() {
    // const array = [];
    // switch (page) {
    //   case expression:
    //     break;
    //   default:
    // }
  }

  render() {
    return (
      <div>
        <Drawer open={this.props.open} onClose={() => this.props.liftUpStateToApp({ mainMenuOpen: false })}>
          <List>{this.renderMainMenuLinks(linksMainMenu)}</List>
          <Divider />
          <List>{this.renderMainMenuLinks(linksGeneralMenu)}</List>
          <Divider />

          <List>{this.renderMainMenuLinks(linksAdminMenu)}</List>
          <Divider />

          {/* <List>{this.renderPageMenuLinks(this.props.page)}</List> */}
          <List>{this.renderMainMenuLinks(linksBottomMenu)}</List>
        </Drawer>
      </div>
    );
  }
}

MainMenuDrawer.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  // page: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};
export default withUser(MainMenuDrawer);
