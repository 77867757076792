import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import applicantColumns from './ApplicantTableColumns';
import TEXT from './text';

const muiTheme = {
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        '&:nth-child(1)': {
          //    width: '70px'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    MUIDataTableBodyCell: {
      root: {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
};

/**
 * Maps the date for date table
 * IMPORTANT!: The order of the object have to match the columns def order
 *
 * @param {import('../pages/LienertIncomingApplicantUser').LienertApplicationUserDTO} data
 */
const applicantListMapper = data => ({
  id: data.id,
  title: data.title,
  firstName: data.firstName,
  lastName: data.lastName,
  mail: data.mail,
  phoneNumber: data.phoneNumber,
  birthDate: data.birthDate,
  street: data.street,
  postalCode: data.postalCode,
  city: data.city,
  createdAt: data.createdAt
});

/**
 * @typedef Props
 * @property {import('../pages/LienertIncomingApplicantUser').LienertApplicationUserDTO[]} applicantList
 * @property {(id: string) => void} onApplicantClick
 */

/**
 *
 * @param {Props} props
 */
const IncomingLienertApplicantTable = ({ applicantList = [], onApplicantClick }) => {
  const preparedData = useMemo(() => applicantList.map(item => [...Object.values(applicantListMapper(item))]), [applicantList]);

  const handleOnRowClick = useCallback(
    /**
     *
     * @param {string[]} rowData
     */
    rowData => {
      onApplicantClick(rowData[0]);
    },

    [onApplicantClick]
  );

  return (
    <div className="h-100 pt-2">
      <SafeMuiDatatable
        title={
          <Box display="flex">
            <Typography variant="h6">{TEXT.tableTitle}</Typography>
          </Box>
        }
        tableName="lienertApplicantTable"
        onRowClick={handleOnRowClick}
        columns={applicantColumns.DEFAULT_COLUMNS}
        data={preparedData}
        muiTheme={muiTheme}
      />
    </div>
  );
};

IncomingLienertApplicantTable.defaultProps = {
  onApplicantClick: () => {}
};

IncomingLienertApplicantTable.propTypes = {
  applicantList: PropTypes.array.isRequired,
  onApplicantClick: PropTypes.func
};

export default IncomingLienertApplicantTable;
