import { useCallback, useEffect, useState } from 'react';

const defaultOptions = {
  enabledOnInit: true
};

/**
 *
 */
const useApiQuery = (afn, options = defaultOptions) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(undefined);

  const { enabledOnInit } = options;

  const callApi = useCallback(() => {
    // Set states
    setIsLoading(true);
    setIsFailed(false);
    setIsSuccess(false);
    setError(undefined);

    afn()
      .then(result => {
        setData(result);
        setIsSuccess(true);
      })
      .catch(e => {
        setError(e);
        setIsFailed(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [afn]);

  useEffect(() => {
    if (enabledOnInit) {
      callApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledOnInit]);

  return {
    isLoading,
    isFailed,
    isSuccess,
    data,
    error,
    refetch: callApi
  };
};

export default useApiQuery;
