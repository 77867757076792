import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';
import * as CONSTANTS from '../constants';
import IncomingLienertApplicantTable from '../incomingLienertApplicant/IncomingLienertApplicantTable';
import TEXT from '../text';
import APPLICANT_TEXT from '../incomingLienertApplicant/text';
import { post } from '../utils/apiUtils';
import withUser from '../utils/withUser';

/**
 * @typedef LienertApplicationUserDTO
 * @property {string}         id
 * @property {string}         title
 * @property {string}         firstName
 * @property {string}         lastName
 * @property {string}         mail
 * @property {string}         phoneNumber
 * @property {string}         birthDate  date as iso string
 * @property {string}         street
 * @property {string}         postalCode
 * @property {string}         city
 * @property {string}         linkedIn
 * @property {string}         xing
 * @property {string=}        candidateId
 * @property {string=}        incomingCandidateId
 * @property {string}         keycloakId
 */

class LienertIncomingApplicantUser extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      /**
       * @type {LienertApplicationUserDTO[]}
       */
      lienertApplicants: [],

      loading: false
    };
    this.handleOnChangeSearch = flowRight(
      debounce(this.handleOnChangeSearch, 500),
      e => e.persist() || e
    );
  }

  /**
   *
   * @param {string=} nameQuery
   */
  getManyIncomingLienertApplicantUser = nameQuery => {
    this.setState({ loading: true });
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    post(CONSTANTS.getManyIncomingLienertApplicantUserURL, {
      nameQuery
    })
      .then(resBody => {
        if (resBody) {
          this.setState({
            lienertApplicants: resBody.applicants
          });
        }
      })
      .catch(() => {
        this.props.handleSnackbarOpen(TEXT.lienetApplicant.errors.getMany);
      })
      .finally(() => {
        this.setState({ loading: false });
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  };

  /**
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e
   */
  handleOnChangeSearch = e => {
    const queryString = e.target.value || undefined;

    if (queryString) {
      this.getManyIncomingLienertApplicantUser(e.target.value);
    }
  };

  handleOnClickApplicant = id => {
    this.props.history.push(`/applicantUser/details?id=${id}`);
  };

  componentDidMount() {
    this.getManyIncomingLienertApplicantUser();
  }

  render() {
    return (
      <div className="container-fluid d-flex flex-column">
        <div className="m-3 w-full d-flex flex-column align-items-center">
          <TextField
            style={{ width: '400px' }}
            disabled={this.state.loading}
            onChange={this.handleOnChangeSearch}
            placeholder={APPLICANT_TEXT.searchField.placeHolder}
            variant="outlined"
          />
        </div>
        <IncomingLienertApplicantTable applicantList={this.state.lienertApplicants} onApplicantClick={this.handleOnClickApplicant} />
      </div>
    );
  }
}

LienertIncomingApplicantUser.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  history: PropTypes.any.isRequired
};

export default withUser(LienertIncomingApplicantUser);
