import { Box, Button, LinearProgress, Paper, Typography, Grid, Divider } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { ArrowBack, People } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { getURLParam } from '../utils/utilFunctions';
import useApiQuery from './hooks/useApiQuery';
import { post } from '../utils/apiUtils';
import { getIncomingLienertApplicantUserByIdURL } from '../constants';
import TEXT from './text';
import ProjectMatchingForm from '../projectArchive/ProjectMatchingForm';

/**
 * @typedef Props
 * @property {object} history - React Routers history object
 */

/**
 *
 * @param {Props} param0
 * @returns
 */

const IncomingLienertApplicantDetail = ({ history }) => {
  const id = useMemo(() => getURLParam(history, 'id'), [history]);
  const { data, isLoading, isSuccess, isFailed } = useApiQuery(() => post(getIncomingLienertApplicantUserByIdURL, { id }), {
    enabledOnInit: !!id
  });

  const handleOncClickToCandidateButton = useCallback(() => {
    history.push(`/candidates?id=${data.candidateId}`);
  }, [history, data]);

  return (
    <div className="container pt-4">
      <Button startIcon={<ArrowBack />} onClick={() => history.goBack()} variant="outlined">
        {TEXT.lienertApplicantDetails.actions.backButton}
      </Button>
      <div className="pt-4">
        <Paper style={{ padding: '50px', marginBottom: '10px' }}>
          {isFailed && <Typography color="error">{TEXT.lienertApplicantDetails.error.loadingError}</Typography>}
          {isLoading && <LinearProgress />}
          {isSuccess && (
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box display="flex" flexDirection="column" style={{ gap: '20px' }}>
                  <Box marginBottom={4}>
                    <Typography variant="subtitle1">{TEXT.lienertApplicantDetails.title.headerData}</Typography>
                    <Divider />
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption">{TEXT.lienertApplicantDetails.title.firstName}</Typography>
                    <Typography variant="subtitle2">{data.firstName}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption">{TEXT.lienertApplicantDetails.title.lastName}</Typography>
                    <Typography variant="subtitle2">{data.lastName}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption">{TEXT.lienertApplicantDetails.title.email}</Typography>
                    <Typography variant="subtitle2">{data.mail}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption">{TEXT.lienertApplicantDetails.title.phoneNumber}</Typography>
                    <Typography variant="subtitle2">{data.phoneNumber}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption">{TEXT.lienertApplicantDetails.title.city}</Typography>
                    <Typography variant="subtitle2">{data.city}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption">{TEXT.lienertApplicantDetails.title.postalCode}</Typography>
                    <Typography variant="subtitle2">{data.postalCode}</Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="caption">{TEXT.lienertApplicantDetails.title.street}</Typography>
                    <Typography variant="subtitle2">{data.street}</Typography>
                  </Box>
                </Box>
                <Divider style={{ marginTop: '10px', marginBottom: '10px' }} />
                <Box display="flex" flexDirection="column" style={{ gap: '20px', marginTop: '10px' }}>
                  {data.candidateId && (
                    <Button onClick={handleOncClickToCandidateButton} variant="outlined" startIcon={<People />}>
                      {TEXT.lienertApplicantDetails.actions.toCandidateButton}
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box marginBottom={4}>
                  <Typography variant="subtitle1">{TEXT.lienertApplicantDetails.title.headerMatchingProfile}</Typography>
                  <Divider />
                </Box>
                {isSuccess && data.matchingProfile ? (
                  <Box marginTop="-48px">
                    <ProjectMatchingForm
                      key={`${id}-matching`}
                      selectedProjectId="noProject"
                      data={data.matchingProfile}
                      handleSnackbarOpen={() => {}}
                      inCandiateArchive
                    />
                  </Box>
                ) : (
                  <Typography>{TEXT.lienertApplicantDetails.error.noData}</Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Paper>
      </div>
    </div>
  );
};

IncomingLienertApplicantDetail.propTypes = {
  history: PropTypes.object.isRequired
};

export default IncomingLienertApplicantDetail;
